<template>
  <div>
    <section class="bg-secondary bg-opacity-10">
      <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light text-dark">
          <div class="container-fluid">
            <a class="navbar-brand" href="https://pagestatus.net">
              <img
                src="../assets/logo.png"
                width="40"
                height="40"
                alt=""
                srcset=""
              />
              <strong class="px-2">Page Status</strong>
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Link</a>
            </li>
          </ul>
        </div> -->
          </div>
        </nav>
      </div>
    </section>

    <section class="py-3" v-if="!showResult">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <form @submit.prevent="submitJob">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      URL Website
                    </label>
                    <input
                      autofocus
                      type="text"
                      class="form-control"
                      id="exampleInputurl1"
                      aria-describedby="urlHelp"
                      v-model="url"
                    />
                    <div id="urlHelp" class="form-text">
                      Enter your full URL. Including prefix www, http or https
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-md-6 mt-2">
                      <h5 class="text-center">Device</h5>
                      <div
                        class="form-check"
                        v-for="device in devices"
                        v-bind:key="device.name"
                      >
                        <input
                          @click="addRemoveDevices(device.name)"
                          class="form-check-input"
                          type="checkbox"
                          :id="'device-' + device.name"
                          :checked="isCheckedDevice(device.name)"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          {{ device.description }} - ({{ device.width }} x
                          {{ device.height }})
                        </label>
                      </div>
                    </div>

                    <div class="col-md-6 mt-2">
                      <h5 class="text-center">Location</h5>
                      <div
                        class="form-check"
                        v-for="region in regions"
                        v-bind:key="region.slug"
                      >
                        <input
                          @click="addRemoveregions(region.slug)"
                          class="form-check-input"
                          type="checkbox"
                          :id="'region-' + region.slug"
                          :checked="isCheckedregion(region.slug)"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          {{ region.displayName }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="text-center mt-4">
                    <button type="submit" class="btn btn-primary">
                      Run Page Status
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-light py-3" v-if="showResult">
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <button
              @click="showResult = !showResult"
              type="button"
              class="btn btn-warning"
            >
              Run Again
            </button>
            <h6 class="mt-2">{{ url }}</h6>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-light py-3" v-if="showResult">
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li
                class="nav-item"
                role="resultJob"
                v-for="(device, index) in dataResultJob"
                v-bind:key="device.viewport_name"
              >
                <button
                  class="nav-link btn-sm"
                  v-bind:class="{ active: index == 0 }"
                  data-bs-toggle="pill"
                  type="button"
                  role="tab"
                  @click="changeShowDevice(device.viewport_name)"
                >
                  {{ device.viewport.description }}
                  <br />
                  {{ device.viewport.width }} x {{ device.viewport.height }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-light py-3" v-if="showResult">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="tab-content" id="pills-tabContent">
              <div class="row row-cols-1 row-cols-md-3 g-4">
                <div
                  class="col"
                  v-for="region in dataResultJobShow.regions"
                  v-bind:key="region.name"
                >
                  <div class="card h-100">
                    <img src="" class="card-img-top" alt="" />
                    <div class="card-body m-0 p-0">
                      <div
                        v-if="!region.thumbnail.signedUrl"
                        class="text-center py-5"
                      >
                        <h5>Loading . . .</h5>
                      </div>
                      <div
                        @click="showImage(region.original.signedUrl)"
                        v-else
                        style="height: 200px; overflow-y: auto; width: 100%"
                      >
                        <img
                          class="img-fluid m-0 p-0"
                          :src="region.thumbnail.signedUrl"
                          :alt="region.name"
                        />
                      </div>
                    </div>
                    <div class="card-footer">
                      <h5>
                        {{ region.regionDetail.displayName }}
                      </h5>
                      <div class="d-flex justify-content-between">
                        <small class="text-muted">
                          <span v-if="!region.dnsResolve.ip">Loading</span>
                          <span v-else>{{ region.dnsResolve.ip }}</span>
                        </small>
                        <small class="text-muted">
                          <span v-if="!region.ping">Loading</span>
                          <span v-else>
                            <span v-if="region.ping == 'unknown'">
                              Ping Failed
                            </span>
                            <span v-else>{{ region.ping }} ms</span>
                          </span>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { devices, regions } from "../dataset/dataset.json";
import pusher from "../apis/pusher";
import axios from "axios";

export default {
  name: "Index",
  components: {},
  data: () => ({
    dataResultJob: [],
    dataResultJobShow: [],
    currentResultShow: "",
    teamId: "",
    jobId: "",
    showResult: false,
    devices: devices,
    regions: regions,
    url: "",
    devicesSelectected: [
      "iphone-6-7-8-plus",
      "ipad",
      "12-inch-notebook",
      "14-inch-notebook",
    ],
    regionsSelected: [
      "iowa",
      "belgium",
      "london",
      "frankfurt",
      "hongkong",
      "seoul",
      "jakarta",
      "saopaulo",
      "sydney",
    ],
  }),
  methods: {
    addRemoveDevices(deviceName) {
      if (this.devicesSelectected.includes(deviceName)) {
        this.devicesSelectected = this.devicesSelectected.filter(
          (device) => device !== deviceName
        );
      } else {
        this.devicesSelectected.push(deviceName);
      }
    },
    isCheckedDevice(deviceName) {
      return this.devicesSelectected.includes(deviceName);
    },
    addRemoveregions(regionSlug) {
      if (this.regionsSelected.includes(regionSlug)) {
        this.regionsSelected = this.regionsSelected.filter(
          (region) => region !== regionSlug
        );
      } else {
        this.regionsSelected.push(regionSlug);
      }
    },
    isCheckedregion(regionSlug) {
      return this.regionsSelected.includes(regionSlug);
    },
    async submitJob() {
      if (this.url == "") {
        alert("Please enter URL");
        return;
      }
      this.showResult = true;

      this.configureDataResultJob();
      try {
        const newUrl = this.setHttp(this.url);
        this.url = newUrl;
        const response = await axios.post(
          "https://us-central1-pagestatus-net.cloudfunctions.net/pagestatus-api",
          {
            url: this.url,
            channel: localStorage.getItem("channelId"),
            devices: this.devicesSelectected,
            regions: this.regionsSelected,
          }
        );

        this.teamId = response.data.data.teamId;
        this.jobId = response.data.data.jobId;
      } catch (error) {
        console.log(error);
      }
    },
    async configureDataResultJob() {
      this.dataResultJob = [];
      this.currentResultShow = "";

      for await (let deviceName of this.devicesSelectected) {
        const deviceDetail = await this.devices.find(
          (device) => device.name === deviceName
        );

        let regionsDetail = [];
        for await (let regionSlug of this.regionsSelected) {
          const regionDetail = await this.regions.find(
            (region) => region.slug === regionSlug
          );
          let reg = {
            viewport_name: this.deviceName,
            name: regionSlug,
            regionDetail: regionDetail,
            original: {},
            thumbnail: {},
            ping: "",
            dnsResolve: {},
          };
          regionsDetail.push(reg);
        }

        this.dataResultJob.push({
          viewport_name: deviceName,
          viewport: deviceDetail,
          regions: regionsDetail,
        });
      }

      await this.changeShowDevice();
    },

    async changeShowDevice(deviceName = "") {
      if (deviceName == "") {
        deviceName = this.currentResultShow;
      }
      if (this.currentResultShow == "") {
        deviceName = this.dataResultJob[0].viewport_name;
      }
      this.dataResultJobShow = await this.dataResultJob.find(
        (device) => device.viewport_name === deviceName
      );
      this.currentResultShow = this.dataResultJobShow.viewport_name;
    },
    showImage(url) {
      window.open(url, "_blank");
    },
    setHttp(link) {
      if (link.search(/^http[s]?:\/\//) == -1) {
        link = "http://" + link;
      }
      return link;
    },
  },
  mounted() {
    if (localStorage.getItem("channelId") === null) {
      localStorage.setItem("channelId", uuidv4());
    }
    const channelId = localStorage.getItem("channelId");
    let channel = pusher.subscribe(channelId);

    channel.bind("ping", async (data) => {
      if (data.jobId == this.jobId && data.teamId == this.teamId) {
        const viewportName = data.viewportName;
        const regionName = data.region;

        const indexDevice = await this.dataResultJob.findIndex(
          (device) => device.viewport_name === viewportName
        );

        const device = await this.dataResultJob.find(
          (device) => device.viewport_name === viewportName
        );

        const regionIndex = await device.regions.findIndex(
          (region) => region.name === regionName
        );

        this.dataResultJob[indexDevice].regions[regionIndex].ping = data.ping;
        await this.changeShowDevice();
      }
    });

    channel.bind("dns-resolve", async (data) => {
      if (data.jobId == this.jobId && data.teamId == this.teamId) {
        const viewportName = data.viewportName;
        const regionName = data.region;

        const indexDevice = await this.dataResultJob.findIndex(
          (device) => device.viewport_name === viewportName
        );

        const device = await this.dataResultJob.find(
          (device) => device.viewport_name === viewportName
        );

        const regionIndex = await device.regions.findIndex(
          (region) => region.name === regionName
        );

        this.dataResultJob[indexDevice].regions[regionIndex].dnsResolve =
          data.dnsResolve;
        await this.changeShowDevice();
      }
    });

    channel.bind("job-finished", async (data) => {
      if (data.jobId == this.jobId && data.teamId == this.teamId) {
        const viewportName = data.viewportName;
        const regionName = data.region;

        const indexDevice = await this.dataResultJob.findIndex(
          (device) => device.viewport_name === viewportName
        );

        const device = await this.dataResultJob.find(
          (device) => device.viewport_name === viewportName
        );

        const regionIndex = await device.regions.findIndex(
          (region) => region.name === regionName
        );

        this.dataResultJob[indexDevice].regions[regionIndex].original =
          data.result.original;
        this.dataResultJob[indexDevice].regions[regionIndex].thumbnail =
          data.result.thumbnail;

        await this.changeShowDevice();
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
a {
  color: #42b983;
}
</style>
